<!-- 区企互动 -->
<template>
  <div style="height:100%">
    <div>
      <div
        style="
          border-bottom: 1px solid #e6e6e6;
          padding: 10px 15px;
          position: relative;
        "
      >
        <div style="display: inline-block; width: 60px">
          <img style="width: 90%" src="../../assets/icon_interactive_selected@2x.png" alt="" />
        </div>
        <div
          style="
            display: inline-block;
            position: absolute;
            top: 17px;
            margin-left: 15px;
          "
        >
          <div style="font-size: 18px">吉利华阳企业公共服务平台</div>
          <div>区企互动</div>
        </div>
        <!-- <router-link to="/huayang/interaction/suggest">
          <button class="onRecordn">提交记录</button>
        </router-link> -->
      </div>
      <div class="inputFrom">
        <div>名称</div>
        <div style="margin-top: 10px">
          <input
            type="text"
            placeholder="请输入提交人名称"
            v-model="datafrom.Name"
            style="border: none; border-bottom: 1px solid #999999"
          />
        </div>
        <div style="margin: 25px 0px">
          <span>平台评分</span>
          <van-rate
            v-model="datafrom.Live"
            :size="20"
            :gutter="10"
            color="#FF9E14"
            style="margin-left: 30px"
            @change="clickRate"
          />
        </div>
        <div style="margin-top: 10px">意见建议</div>
        <div>
          <textarea
            v-model="datafrom.Idea"
            rows="6"
            placeholder="欢迎阐述自己的观点"
            style="
              width: 90%;
              margin: 15px 0px;
              border: none;
              font-size: 14px;
              border-radius: 5px;
              background: #f7f7f7;
              padding: 8px 15px;
            "
          ></textarea>
        </div>
        <!-- <span>合理化建言，一经采纳，予以奖励！</span> -->
      </div>
      <div class="fromButton">
        <van-button
          style="width: 80%; margin: 10px 10%; border-radius: 25px"
          type="info"
          @click="tijiao()"
          >提 交</van-button
        >
      </div>
    </div>
    <div>
      <van-tabbar v-model="active">
        <van-tabbar-item icon="home-o" to="/huayang/classroom"
          >知识讲堂</van-tabbar-item
        >
        <van-tabbar-item icon="qr" to="/huayang/serve"
          >资源看板</van-tabbar-item
        >
        <van-tabbar-item icon="apps-o" to="/huayang">中介超市</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" to="/huayang/interaction"
          >区企互动</van-tabbar-item
        >
        <van-tabbar-item icon="phone-circle-o" to="/huayang/lianluo"
          >联系我们</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Rate } from "vant";
import { Dialog } from "vant";
import { WeSubInteract } from "@/api/Hyapi";

Vue.use(Dialog);
Vue.use(Rate);
export default {
  data() {
    return {
      active: 3,
      datafrom: {
        EId: 0, //企业Id、
        OpenID: "djksvnjkdfnbkjdfnjbndfjkb", //微信公众号ID、
        Idea: "", //意见建议、
        Live: "", 
        Name:"",
        Remark:"", //备注
      },
    };
  },
  methods: {
    tijiao() {
      if (this.datafrom.Live == "") {
        Dialog.alert({
          title: "提示",
          message: "请评分!",
        }).then(() => {
          // on close
        });
        return false;
      }
      if (this.datafrom.Idea == "") {
        Dialog.alert({
          title: "提示",
          message: "请输入意见建议!",
        }).then(() => {
          // on close
        });
        return false;
      }
      WeSubInteract(this.datafrom).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "提交成功!",
          }).then(() => {
            this.datafrom.EId = undefined;
            this.datafrom.Live = "";
            this.datafrom.Idea = "";
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "提交失败!",
          }).then(() => {
          });
        }
      });
    },
    clickRate(e) {
      console.log(e);
    },
  },
};
</script>
<style>
.inputFrom {
  margin: 15px 8%;
}
.inputFrom input {
  width: 93%;
  margin: 8px 2%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.margin {
  margin: 15px 30px;
}
.onRecordn {
  position: absolute;
  right: 15px;
  bottom: 27px;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  background: #1989fa;
  color: #fff;
  font-size: 14px;
}
</style>